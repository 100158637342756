/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var ctrl;
	ctrl = {
		// All pages
		'common': {
			init: function () {
				//Common element variables
				var $headerTop = $('#header-top');

				// JavaScript to be fired on all pages
				//Flexible Content Focus Point Init
				$('.focuspoint img').on('lazyload', function (e) {
					$(this).parent().focusPoint();
				});

				var headerWaypoint = new Waypoint({
					element: $('main.content'),
					offset: '20%',
					handler: function (direction) {
						if (direction === 'down') {
							$headerTop.addClass('scrolled');
						} else {
							$headerTop.removeClass('scrolled');
						}
						console.log('Direction: ' + direction);
					}
				});

				//Match Height
				$('.matchHeight .brick .inner').matchHeight();

				//Fancybox!
				$(".lightbox-link").fancybox();
				$('a[rel="lightbox"]').fancybox();

				//Full Screen Navigation
				var navOpen = false;
				$headerTop.on("click", "#menu-toggle", function (e) {
					e.preventDefault();

					var $offPageMenu = $('#off-page-menu');

					if (navOpen === false) {
						navOpen = true;
						$('html').addClass('nav-open');
						$(this).addClass('open');
						$offPageMenu.velocity("fadeIn", {duration: 300});
						$('span', $offPageMenu).velocity("transition.slideDownBigIn", {
							stagger: 150
						});
					} else {
						navOpen = false;
						$offPageMenu.velocity("fadeOut", {duration: 300});
						$(this).removeClass('open');
						$('html').removeClass('nav-open');
					}
				});

				var searchOpen = false;
				$('#search-toggle').click(function(){
					if (searchOpen === false) {
						searchOpen = true;
						$('#search-overlay').fadeIn(200);
						$('#search-overlay').addClass('active');
					} else {
						searchOpen = false;
						$('#search-overlay').fadeOut(200);
						$('#search-overlay').removeClass('active');
					}
				});

				$("body").on('click', '#search-overlay, #search-overlay .search-align', function(e) {

					if($(e.target).closest('#search-form').length === 0) {

						searchOpen = false;
						$('#search-overlay').fadeOut(200);
						$('#search-overlay').removeClass('active');

					}
				});

				//Header Carousel
				$('.carousel').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 6000,
					fade: true,
					cssEase: 'linear',
					arrows: false,
					pauseOnHover: false
				});

				//Check to see if the window is top if not then display button
				$(window).scroll(function(){
					if ( $(this).scrollTop() > 400 ) {
						$('.backToTop').fadeIn(600);
					} else {
						$('.backToTop').fadeOut(200);
					}
				});

				//Click event to scroll to top
				$('.backToTop').click(function(){
					$('html, body').animate({scrollTop : 0},800);
					return false;
				});

				//Animations for Desktops!
				var $hero = $('#hero');

				if (device.desktop() && !$('body').hasClass( 'post-type-archive-projects' ) && !$('body').hasClass( 'post-type-archive-team-members' ) ) {

					if (!$(window).scrollTop()) {
						//Set hero width
						setTimeout(heroSvgWidth(0), 300);
						$(window).resize(function () {
							heroSvgWidth(1);
						});

						$('html').addClass('buildin');

						$('svg#logo').velocity("transition.shrinkIn", {
							delay: 200
						});

						$('.sub-title', $hero).velocity("transition.slideLeftBigIn", {
							stagger: 600,
							delay: 1000
						});

						$('h2', $hero).velocity("transition.slideLeftBigIn", {
							stagger: 600,
							delay: 1200
						});

						$('.text svg', $hero).velocity("transition.slideLeftBigIn", {
							stagger: 600,
							delay: 1400,
						});

						$('.intro .first').velocity("transition.slideUpBigIn", {
							stagger: 1000,
							delay: 1600
						});

						$('.intro .extras').velocity("transition.slideUpBigIn", {
							stagger: 1000,
							delay: 1800
						});

						$('.wall').waypoint({
							handler: function (direction) {
								if (!$(this.element).parent(".intro").length) {
									if (direction === 'down') {
										$(this.element).find('.brick').velocity("transition.slideUpBigIn", {
											stagger: 350
										});
										this.destroy();
									}
								}
							},
							offset: '70%'
						});

						$('.columns').waypoint({
							handler: function (direction) {
								if (direction === 'down') {
									$(this.element).find('.column').velocity("transition.slideUpBigIn", {
										stagger: 350
									});
									this.destroy();
								}
							},
							offset: '80%'
						});
					} else {
						//Set hero width
						setTimeout(heroSvgWidth(1), 300);
						$('.sub-title', $hero).velocity("transition.slideLeftBigIn", {
							stagger: 0,
							delay: 0
						});

						$(window).resize(function () {
							heroSvgWidth(1);
							$('.sub-title', $hero).velocity("transition.slideLeftBigIn", {
								stagger: 0,
								delay: 0
							});
						});
					}
				} else {
					//Set hero width
					setTimeout(heroSvgWidth(1), 300);

					$(window).resize(function () {
						heroSvgWidth(1);
					});
				}
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
				$(window).on("scroll", headerParalax);
				$(window).on("resize", headerParalax);
				headerParalax();

				$.extend($.lazyLoadXT, {
					edgeY:	300,
					forceLoad: true
				});

				var $sliderBlock = $('.slider-block');

				$sliderBlock.slick({
					centerMode: true,
					infinite: false,
					centerPadding: '0px',
					slidesToShow: 1
				});

				$sliderBlock.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
					$('.focuspoint', $sliderBlock).focusPoint();
					$('.focuspoint', $sliderBlock).data('focusPoint').adjustFocus();
				});

				if (device.desktop()) {

					if (!$(window).scrollTop()) {
						$('html').addClass('buildin');

						$sliderBlock.waypoint({
							handler: function (direction) {
								if (direction === 'down') {
									$(this.element).find('.block').velocity("transition.slideUpBigIn", {
										stagger: 350
									});
									this.destroy();
								}
							},
							offset: '70%'
						});
					}
				}
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		},
		// About us page, note the change from about-us to about_us.
		'post_type_archive_team_members': {
			init: function () {

				$.extend($.lazyLoadXT, {
					edgeY:	300,
					forceLoad: true
				});

				var $hero = $('#hero');
				if (!$(window).scrollTop()) {
					//Set hero width
					setTimeout(heroSvgWidth(0), 300);
					$(window).resize(function () {
						heroSvgWidth(1);
					});

					$('svg#logo').velocity("transition.shrinkIn", {
						delay: 200
					});

					$('.text svg', $hero).velocity("transition.slideLeftBigIn", {
						stagger: 600,
						delay: 1400,
					});
				};

				$('.mixup').mixItUp({
					selectors: {
						target: '.mix-target',
						filter: '.filter-btn',
						sort: '.sort-btn'
					}
				});
			}
		},
		// Projects Archive.
		'post_type_archive_projects': {
			init: function () {
				$.extend($.lazyLoadXT, {
					edgeY:	300,
					forceLoad: true
				});

				var $hero = $('#hero');
				if (!$(window).scrollTop()) {
					//Set hero width
					setTimeout(heroSvgWidth(0), 300);
					$(window).resize(function () {
						heroSvgWidth(1);
					});

					$('svg#logo').velocity("transition.shrinkIn", {
						delay: 200
					});

					$('.text svg', $hero).velocity("transition.slideLeftBigIn", {
						stagger: 600,
						delay: 1400,
					});
				};

				//MixUp Filtering
				var advancedFilter = {
					$filters: null,
					$reset: null,
					groups: [],
					outputArray: [],
					outputString: '',

					// The "init" method will run on document ready and cache any jQuery objects we will need.

					init: function(){
						var self = this;
						self.$filters = $('.filter');
						self.$container = $('.mixup');

						self.$filters.find('ul').each(function(){
							self.groups.push({
								$inputs: $(this).find('.filter-btn'),
								active: [],
								tracker: false
							});
						});

						self.bindHandlers();
					},

					bindHandlers: function(){
						var self = this;

						self.$filters.find('.filter-btn').on('click', function(){
							$(this).toggleClass('active');
							self.parseFilters();
						});
					},

					parseFilters: function(){
						var self = this;

						for(var i = 0, group; group = self.groups[i]; i++){
							group.active = []; // reset arrays
							group.$inputs.each(function(){
								$(this).hasClass('active') && group.active.push( $(this).data('filter') );
							});
							group.active.length && (group.tracker = 0);
						}

						self.concatenate();
					},


					concatenate: function(){
						var self = this,
						cache = '',
						crawled = false,
						checkTrackers = function(){
							var done = 0;

							for(var i = 0, group; group = self.groups[i]; i++){
								(group.tracker === false) && done++;
							}

							return (done < self.groups.length);
						},
						crawl = function(){
							for(var i = 0, group; group = self.groups[i]; i++){
								group.active[group.tracker] && (cache += group.active[group.tracker]);

								if(i === self.groups.length - 1){
									self.outputArray.push(cache);
									cache = '';
									updateTrackers();
								}
							}
						},
						updateTrackers = function(){
							for(var i = self.groups.length - 1; i > -1; i--){
								var group = self.groups[i];

								if(group.active[group.tracker + 1]){
									group.tracker++;
									break;
								} else if(i > 0){
									group.tracker && (group.tracker = 0);
								} else {
									crawled = true;
								}
							}
						};

						self.outputArray = []; // reset output array

						do{
							crawl();
						}
						while(!crawled && checkTrackers());

						self.outputString = self.outputArray.join();

						// If the output string is empty, show all rather than none:

						!self.outputString.length && (self.outputString = 'all');
						//console.log(self.outputArray);
						// ^ we can check the console here to take a look at the filter string that is produced
						// Send the output string to MixItUp via the 'filter' method:

						if(self.$container.mixItUp('isLoaded')){
							self.$container.mixItUp('filter', self.outputString);
						}
					}
				};

				advancedFilter.init();

				$('.mixup').mixItUp({
					selectors: {
						target: '.mix-target',
						filter: '.filter-btn',
						sort: '.sort-btn'
					},
					controls: {
						enable: false
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = ctrl;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	WebFontConfig = {
		custom: {
			families: ['AvenirLTStd-Medium','AvenirLTStd-Book','AvenirLTStd-Black','AvenirLTStd-Heavy']
		},
		active: function(){
			if (device.desktop() && !$(window).scrollTop() ){
				heroSvgWidth(0);
			}else{
				heroSvgWidth(1);
			}

			console.log('Fonts loaded');
		}
	};

	(function(d) {
		var wf = d.createElement('script'), s = d.scripts[0];
		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js';
		s.parentNode.insertBefore(wf, s);
	})(document);

	// Load Events
	$(document).ready(UTIL.loadEvents);

	function heroSvgWidth(opacity) {
		$("#hero .text svg").each(function () {
			$(this).after('<span class="title">' + $('text', this).text() + '</span>');
			var svgWidth = $(this).nextAll('span').eq(0).width();
			svgWidth += $(this).nextAll('span').eq(0).height();

			$(this).nextAll('span').eq(0).remove();
			$(this).attr('width', svgWidth + 'px');
			$(this).css('opacity', opacity);

		});
	}

	function headerParalax() {
		var $heroText = $('#hero .text');

		var scrollPosition = $(window).scrollTop();
		var textY = scrollPosition * -0.02;

		$heroText.css({
			'-webkit-transform' : 'translate3d(0,' + textY + 'em, 0)',
			'-moz-transform'    : 'translate3d(0,' + textY + 'em, 0)',
			'-ms-transform'     : 'translate3d(0,' + textY + 'em, 0)',
			'-o-transform'      : 'translate3d(0,' + textY + 'em, 0)',
			'transform'         : 'translate3d(0,' + textY + 'em, 0)'
		});

	}

})(jQuery); // Fully reference jQuery after this point.